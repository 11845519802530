<template>
  <div>
    <div class="box">
      <div v-for="pic in currentPictures" :key="pic.uid" class="img_wrapper">
        <img class="height" :src="pic.url" />
      </div>
    </div>
    <button @click="currentPage > 1 ? (currentPage -= 1) : null">上一页</button>
    <button @click="currentPage < totalPages ? (currentPage += 1) : null">
      下一页
    </button>
  </div>
</template>

<script>
export default {
  mounted() {
    this.getPictures();
  },
  data() {
    return {
      pictures: [],
      currentPage: 1,
      totalPages: 0,
    };
  },
  computed: {
    currentPictures() {
      return this.pictures.slice(
        (this.currentPage - 1) * 9,
        this.currentPage * 9
      );
    },
  },
  methods: {
    getPictures() {
      this.$axios({
        method: "GET",
        url: "/picture/getPictures",
      }).then((res) => {
        this.pictures = res.data.reverse();
        this.totalPages = Math.ceil(this.pictures.length / 9);
      });
    },
  },
};
</script>

<style>
.box {
  display: -webkit-flex;
  display: flex;
  flex-wrap: wrap;
}

.img_wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  margin-bottom: 20px;
  height: 250px;
  width: 250px;
  border: 1px solid black;
  border-radius: 8px;
  overflow: hidden;
}

.height {
  width: auto;
  height: 100%;
}
</style>